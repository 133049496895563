// Merge a `source` object to a `target` recursively
export const ObjectDeepMerge = (
  target: Record<string, any>,
  source: Record<string, any>
) => {
  // Iterate through `source` properties and if an `Object` set property to merge of `target` and `source` properties
  for (const key of Object.keys(source)) {
    if (source[key] instanceof Object)
      Object.assign(source[key], ObjectDeepMerge(target[key], source[key]));
  }

  // Join `target` and modified `source`
  Object.assign(target || {}, source);
  return target;
};

// omit keys from object
export const ObjectOmit = (obj: Record<string, any>, keys: string[]) => {
  const result = { ...obj };
  keys.forEach(function (prop) {
    delete result[prop];
  });
  return result;
};

// sort array of object by key
export const ArrayObjectSortBy = <Value extends Record<string, any>>(
  array: Value[],
  key: string,
  isDesc?: boolean
) => {
  if (array) {
    return array.sort(function (a: Value, b: Value) {
      if (isDesc) {
        if (key == "created_date") {
          //@ts-ignore
          return new Date(b[key]) - new Date(a[key]);
        } else {
          return b[key] - a[key];
        }
      } else {
        return a[key] - b[key];
      }
    });
  } else {
    return array;
  }
};


// reduce array with same objects
export const ReduceArrayFromDuplicate = (items: any[]) => {
  const filteredOption2 = Object.values(items.reduce((acc,cur)=>Object.assign(acc,{[cur.id]:cur}),{}));
  return filteredOption2
}

// generate object when not null
export const parseDataParams = (params) => {
  let newParams = {};
  for (let [k, v] of Object.entries(params)) {
    if (v) {
      newParams[k] = v;
    }
  }

  return newParams;
};


// suffle an array
export const ShuffleArray = (array: any) => {
  let currentIndex = array.length,  randomIndex: number;

  // While there remain elements to shuffle.
  while (currentIndex > 0) {

    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}


// example raw: ["a","b"], obj: {"a" : "aaa", "b" : "bbb", "c": "ccc"}
export const FilteredKeys = (raw: string[], obj: object) => {
  const filtered = Object.keys(obj)
  .filter(key => raw.includes(key))
  .reduce((obj, key) => {
    obj[key] = raw[key];
    return obj;
  }, {});
  return filtered
}